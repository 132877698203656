@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');


$primary-color: #1dbf73;
$secondary-color: #202020;
$text-color: #ffffff;
$background-color: #121212;
$hover-color: #343434;

.generated-reports {
  font-family: 'Poppins', sans-serif;
  margin: 20px;
  padding: 20px;
  background-color: $hover-color;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  h3 {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: $text-color;
  }

  .styled-table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 1em;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

    th, td {
      padding: 12px 15px;
      text-align: left;
    }

    thead tr {
      background-color: $primary-color;
      color: $text-color;
    }

    tbody tr {
      border-bottom: 1px solid #dddddd;
      color:$text-color;
      background-color: $background-color;

      // &.status-terminé {
      //   background-color: #527560;
        
      // }

      // &.status-en-cours {
      //   background-color: #fef9e7;
      // }

      // &.status-en-attente {
      //   background-color: #f9e7e7;
      // }
    }

    .status-badge {
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 0.9em;

      &.terminé {
        background-color: #28a745;
        color: #ffffff;
      }

      &.en-cours {
        background-color: #ffc107;
        color: #ffffff;
      }

      &.en-attente {
        background-color: #dc3545;
        color: #ffffff;
      }
    }

    .download-button {
      padding: 5px 10px;
      background-color: #007bff;
      color: #ffffff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #0056b3;
      }
    }

    .disabled-button {
      padding: 5px 10px;
      background-color: #cccccc;
      color: #666666;
      border: none;
      border-radius: 5px;
      cursor: not-allowed;
    }
  }
}
