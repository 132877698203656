// Variables
$primary-color: #1dbf73;
$secondary-color: #202020;
$text-color: #ffffff;
$background-color: #121212;
$hover-color: #343434;

// Header Styles
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: $secondary-color;
  color: $text-color;

  &__left {
    display: flex;
    align-items: center;

    .header__menu-icon {
      font-size: 1.5rem;
      margin-right: 15px;
      cursor: pointer;
    }

    .header__title {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  &__search {
    display: flex;
    align-items: center;
    background-color: $hover-color;
    padding: 5px 15px;
    border-radius: 25px;

    input {
      background: none;
      border: none;
      color: $text-color;
      outline: none;
      width: 200px;
    }

    .header__search-icon {
      font-size: 1.2rem;
      margin-left: 10px;
    }
  }

  &__right {
    display: flex;
    align-items: center;

    .header__icon {
      font-size: 1.5rem;
      margin-left: 20px;
      cursor: pointer;
      position: relative;

      &:hover {
        color: $primary-color;
      }
    }

    .header__profile {
      display: flex;
      align-items: center;
      margin-left: 20px;

      .header__profile-pic {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .header__profile-info {
        display: flex;
        flex-direction: column;

        .header__name {
          font-weight: bold;
        }

        .header__email {
          font-size: 0.9rem;
          color: $hover-color;
        }
      }
    }
  }
}
