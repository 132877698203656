@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

// Variables
$primary-color: #1dbf73;
$secondary-color: #202020;
$text-color: #ffffff;
$background-color: #121212;
$hover-color: #343434;

// Sidebar Styles
.sidebar {
  background-color: $secondary-color;
  font-family: 'Poppins', sans-serif;
  width: 250px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  color: $text-color;

  &__header {
    padding: 20px;
    background-color: $primary-color;
    text-align: center;

    h2 {
      margin: 0;
      font-size: 1.5rem;
    }
  }

  &__menu {
    list-style: none;
    padding: 20px 0;
    margin: 0;
    flex-grow: 1;

    .sidebar__item {
      display: flex;
      align-items: center;
      padding: 15px 20px;
      cursor: pointer;
      font-size: 1rem;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: $hover-color;
      }

      svg {
        margin-right: 10px;
        font-size: 1.5rem;
      }

      span {
        flex-grow: 1;
      }
    }
  }
}

// Dashboard Content Styles
.dashboard__content {
  margin-left: 250px;
  padding: 20px;
  background-color: $background-color;
  color: $text-color;
  min-height: 100vh;

  // Responsive adjustments
  @media (max-width: 768px) {
    margin-left: 0;
    padding: 10px;
  }
}
