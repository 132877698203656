@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');


$primary-color: #1dbf73;
$secondary-color: #202020;
$text-color: #ffffff;
$background-color: #121212;
$hover-color: #343434;

.overview {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px;
    font-family: 'Poppins', sans-serif;

    .card {
        background-color: $hover-color;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 20px;
        text-align: center;
        transition: transform 0.2s ease;

        &:hover {
            transform: translateY(-5px);
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        }

        h3 {
            font-size: 1.1em;
            margin: 0;
            color: $text-color;
            font-weight: 500;
        }

        .change {
            font-size: 0.9em;
            font-weight: 600;
            
            &.positive {
                color: #27ae60; // Green for positive change
            }

            &.negative {
                color: #e74c3c; // Red for negative change
            }
        }

        &__value {
            font-size: 2.5rem;
            color: $text-color;
            font-weight: 600;
        }
    }
}
